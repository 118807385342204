import { config } from "../config";

const API_URL = config.java_api_url;
const API_URL_AUTH = config.auth_api_url;

export const getNewTokens = async (refreshToken) => {
  return fetch(`${API_URL}/refresh_tokens`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      localStorage.removeItem("jwt");
      return data;
    })
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const authorize = async (email, password) => {
  return fetch(`${API_URL}/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
      authAs: "user",
    }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      if (data.jwt) {
        localStorage.setItem("jwt", JSON.stringify(data.jwt));
      }
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};

export const authorizePhoneCode = async (phone, phoneCode) => {
  return fetch(`${API_URL}/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      phone: phone,
      phoneCode: phoneCode,
      authAs: "user",
      authType: "phone",
    }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      if (data.jwt) {
        localStorage.setItem("jwt", JSON.stringify(data.jwt));
      }
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};

export const sendEmailForgetPassword = async (email) => {
  return fetch(`${API_URL}/users/reset/init/${email}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      if (err.status === 404) {
        throw new Error("Пользователь не найден");
      } else {
        throw new Error(err?.message || "Неизвестная ошибка");
      }
    });
};

export const resetUserPassword = async (token, password) => {
  return fetch(`${API_URL}/users/password/${token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password: password,
    }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      } else {
        throw new Error(err?.message || "Неизвестная ошибка");
      }
    });
};

export const getUserByToken = async (token) => {
  return fetch(`${API_URL}/users/reset/${token}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const registrationUserByToken = async (userData) => {
  return fetch(`${API_URL}/users/registration/${userData.token}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password: userData.password,
      firstName: userData.firstName,
      secondName: userData.secondName,
      lastName: userData.lastName,
      utcOffset: userData.utcOffset,
      userFields: userData.userFields,
    }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};

export const getPhoneCode = async (userData) => {
  return fetch(`${API_URL}/users/generate_phone_code`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: userData.email,
      email: userData.email,
      password: userData.password,
      firstName: userData.firstName,
      secondName: userData.secondName,
      lastName: userData.lastName,
      utcOffset: userData.utcOffset,
      registrationType: userData.registrationType,
      phone: userData.phone,

      realm: "REALM_ALL",
    }),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      } else if (err.status === 401) {
        throw new Error("Пользователь с таким email уже существует");
      }
    });
};
export const getPhoneCodeAuth = async (phone) => {
  return fetch(`${API_URL}/auth/generate_phone_code/${phone}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const getAuthByPhoneIsEnabled = async () => {
  return fetch(`${API_URL}/auth_by_phone_is_enabled`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

/// New OAUTH2

export const registration = async (userData) => {
  const response = await fetch(`${API_URL_AUTH}/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      registrationType: userData.registrationType,
      email: userData.email,
      phone: userData.phone,
      password: userData.password,
      confirmationCode: userData.confirmationCode,
      firstName: userData.firstName,
      secondName: userData.secondName,
      lastName: userData.lastName,
      utcOffset: userData.utcOffset,

      realm: "REALM_ALL",
    }),
  });
  return response;
};

export const getAuthType = async () => {
  return await fetch(`${API_URL_AUTH}/auth-types`, {
    method: "GET",
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const login = async ({
                              username = "",
                              password = "",
                              type,
                              token = "",
                              confirmationCode = ""
                            }) => {
  return await fetch(`${API_URL_AUTH}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
      type: type,
      token: token,
      confirmationCode: confirmationCode,
      authAs: "ROLE_VOTER",
      realm: config.realm,
    }),
  })
      .then((res) => {
        return res.ok ? res.json() : res;
      })

      .then((data) => {
        return data;
      })
      .catch((err) => {
        if (err.status === 500) {
          throw new Error("Сервер временно недоступен");
        }
      });
};

/*---Для двухфакторной авторизации---*/

export const getVerificationCode = async ({
                                            username = "",
                                            password = "",
                                            type,
                                            token = "",
                                          }) => {
  return await fetch(`${API_URL_AUTH}/getVerificationCode`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
      type: type,
      authAs: "ROLE_VOTER",
      realm: config.realm,
    }),
  })
      .then((res) => {
        return res.ok ? res.json() : res;
      })

      .then((data) => {
        return data;
      })
      .catch((err) => {
        if (err.status === 500) {
          throw new Error("Сервер временно недоступен");
        }
      });
};

export const getMe = async (accessToken) => {
  return fetch(`${API_URL_AUTH}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getCode = async (type, value) => {
  return (
    fetch(
      `${API_URL_AUTH}/confirmation-code/delivery_type=${type}@value=${value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      // .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => res)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return res;
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        return;
        throw new Error(err.message);
      })
  );
};

export const validateTokenForReset = async (token) => {
  return fetch(
    `${API_URL_AUTH}/validate-token/token=${token}&purpose=SCOPE_PASSWORD_RESET`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.ok ? res : Promise.reject(res);
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return res;
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      //throw new Error(err.message);
    });
};

export const setNewPassword = async (accessToken, password) => {
  return await fetch(`${API_URL_AUTH}/reset-password/token=${accessToken}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: password,
  })
    .then((res) => (res.ok ? res.json() : res))

    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err.status === 500) {
        throw new Error("Сервер временно недоступен");
      }
    });
};
//

export const getForgotPasswordRequest = async (
  type,
  value,
  clientType = "CLIENT"
) => {
  return fetch(
    `${API_URL_AUTH}/forgot-password/delivery_type=${type}&value=${value}&clientType=${clientType}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};
