import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./components/App/App";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CurrentUserContextProvider } from "./contexts/CurrentUserContext";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      staleTime: Infinity,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CurrentUserContextProvider>
        <QueryClientProvider client={queryClient}>
          <App />
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
        </CurrentUserContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

export { queryClient };
