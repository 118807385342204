import * as Auth from "../Api/Auth";

const useRefreshToken = () => {

    const refreshToken = localStorage.getItem("refreshToken");

    return async () => {
        try {
            const response = await Auth.login({ type: "refresh-token", token: refreshToken })
            if (response.status === 401) {
                localStorage.setItem("callLogout", response.status);
            } else {
                localStorage.setItem("accessToken", response.accessToken);
                return response;
            }
        } catch (err) {
            console.log(err);
        }
    }
}
export default useRefreshToken;
