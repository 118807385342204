import React, {useState} from "react";
import clipIcon from "../../img/clipIcon.svg";
import closeButton from '../../img/VotesPageBlockFilterModal_close_button.svg';
import {useMutation} from "@tanstack/react-query";
import {uploadFile} from "../../Api/api-service";
import useCurrentUserContext from "../../hooks/useCurrentUserContext";

const SpecialOpinionMaterials = (props) => {

    const  {
        questionId,
    } = props

    const { setSpecialOpinion } = useCurrentUserContext();
    const [materials, setMaterials] = useState('');

    const fileMutation = useMutation({
        mutationFn: (file) => {
            return uploadFile(file)
        },
        onSuccess: (data) => {
            setSpecialOpinion({
                question_id: questionId,
                link: data[0],
                hasLink: true
            })
        }
    });

    function onSelectFileHandler(e) {
        const files = e.target.files;
        const file = files[0];
        setMaterials(files[0]?.name)
        if (file !== undefined) {
            const fileName = file.name.replace(/ /g, '--');
            const random = new Date().getTime();
            const fileNameForSave = `${random + '_' + fileName}`;
            const data = new FormData();
            data.append(file.name, file, fileNameForSave);
            const uploadData = {
                data: data
            }
            fileMutation.mutate(uploadData);
        }
    }

    const onHandleDeleteMaterials = () => {
        setMaterials('');
        setSpecialOpinion({
            question_id: questionId,
            link: '',
            hasLink: false
        })
    }

    return (
        <div className="special-opinion-send-file">
            <label htmlFor={questionId} className="special-opinion-send-file__block">
                <img className="special-opinion-send-file__icon" src={clipIcon} alt="иконка"/>
                <span className="special-opinion-send-file__label">ПРИКРЕПИТЬ ФАЙЛ</span>
            </label>
            <input id={questionId}
                   className="special-opinion-send-file__input"
                   type="file"
                   onChange={(e) => onSelectFileHandler(e)}
            />
            {materials &&
                <div className="special-opinion-send-file__name-block">
                    <p className="special-opinion-send-file__name">{materials}</p>
                    <img onClick={() => onHandleDeleteMaterials()} className="special-opinion-send-file__close" src={closeButton} alt="иконка"/>
                </div>
            }
        </div>
    )
}
export default SpecialOpinionMaterials;