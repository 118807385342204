import { config } from "../config";

const API_URL = config.java_api_url;

export const getEvents = (accessToken) => {
  return fetch(`${API_URL}/events/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const getEvent = (accessToken, body) => {
  return fetch(`${API_URL}/events/me/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const registrationUserInEvents = (accessToken, body) => {
  return fetch(`${API_URL}/events/toggle_register/${body.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const vote = (accessToken, body) => {
  return fetch(`${API_URL}/events/vote/${body.eventId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body.eventArray),
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const joinEventByLink = (accessToken, body) => {
  return fetch(`${API_URL}/events/join/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error(err?.message || "Неизвестная ошибка");
    });
};

export const getNetworkStatistics = (accessToken, body) => {
  return fetch(`${API_URL}/events/network-statistics/${body.id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((data) => data)
    .catch((err) => {
      throw new Error();
    });
};


