import { useState, useEffect } from "react";

export const useCallLogout = () => {

    const [isCallLogout, setCallLogout] = useState("");

    useEffect(() => {
        let isMounted = true;

        function updateLocalStorage() {
            const getCallLogout = localStorage.getItem("callLogout");

            if (getCallLogout) {
                setCallLogout(getCallLogout);
            }
        }

        updateLocalStorage();

        window.addEventListener('storage', updateLocalStorage);

        return () => {
            window.removeEventListener("storage", updateLocalStorage);
            isMounted = false;
        };

    }, []);

    return isCallLogout;
};
