import React, {useState} from "react";
import radioActiveIcon from "../../img/radio-active.svg";
import radioNotActiveIcon from "../../img/radioIconInactive.svg";
import "./SpecialOpinion.css";
import useCurrentUserContext from "../../hooks/useCurrentUserContext";
import SpecialOpinionMaterials from "../SpecialOpinion/SpecialOpinionMaterials";

const SpecialOpinion = (props) => {

    const {
        isBulletinVoted,
        questionId
    } = props;

    const { setSpecialOpinion } = useCurrentUserContext();
    const [isActiveSpecialOpinion, setActiveSpecialOpinion] = useState(false);
    const [isActiveLinkMaterials, setActiveLinkMaterials] = useState(true);
    const [textValueOpinion, setTextValueOpinion] = useState('');

    const [isActiveRadioBtn , setActiveRadioBtn] = useState({
        publicSign: true,
        anonymousSign: false
    });

    const onToggleRadioButton = (value) => {
        setSpecialOpinion({})
        if(value === 'public') {
            setActiveRadioBtn({
                publicSign: true,
                anonymousSign: false
            });
            setSpecialOpinion({ question_id: questionId,
                anonymousSignature : false
            });
        } else {
            setActiveRadioBtn({
                publicSign: false,
                anonymousSign: true
            })
            setSpecialOpinion({ question_id: questionId,
                anonymousSignature : true
            });
        }
    }

    function onActiveShowTextField() {
        setSpecialOpinion({})
        if(!isActiveSpecialOpinion) {
            setActiveSpecialOpinion(true);
            setSpecialOpinion({
                question_id : questionId,
                activeComment: true,
            });
        } else {
            setActiveSpecialOpinion(false);
            setActiveRadioBtn({
                publicSign: true,
                anonymousSign: false
            });
            setSpecialOpinion(  {
                question_id : questionId,
                activeComment: false,
            });
        }
    }

    function onChangeTextAreaText(value) {
        setSpecialOpinion({})
        if (value) {
            setSpecialOpinion({
                question_id: questionId,
                text: value.currentTarget.value
            });
        }
    }

    return (
        <div className={isBulletinVoted ? "special-opinion-wrap hidden" : "special-opinion-wrap"}>
            {isActiveSpecialOpinion &&
                <>
                      <textarea
                          className="special-opinion__textarea"
                          placeholder="Добавьте особое мнение"
                          defaultValue={textValueOpinion}
                          onChange={(e) => onChangeTextAreaText(e)}
                      />
                    {isActiveLinkMaterials &&
                        <SpecialOpinionMaterials
                            questionId={questionId}
                        />
                    }
                    <div className="special-opinion-sign">
                        <div onClick={() => onToggleRadioButton('public')} className="special-opinion-sign__item">
                            <img src={isActiveRadioBtn.publicSign ? radioActiveIcon : radioNotActiveIcon}
                                 className="special-opinion-sign__icon" alt="иконка"/>
                            <span className="special-opinion-sign__name-sign">Публичная подпись</span>
                        </div>
                        <div onClick={() => onToggleRadioButton('anonymous')} className="special-opinion-sign__item">
                            <img src={isActiveRadioBtn.anonymousSign ? radioActiveIcon : radioNotActiveIcon}
                                 className="special-opinion-sign__icon" alt="иконка"/>
                            <span className="special-opinion-sign__name-sign">Анонимная подпись</span>
                        </div>
                    </div>
                </>
            }
            <button onClick={() => onActiveShowTextField()} className="special-opinion-button">Добавить особое мнение по вопросу</button>
        </div>
    )
}
export default SpecialOpinion;