import React, {useRef, useState} from "react";
import "../../components/MyProfilePageSetMailPhone/MyProfilePageSetMailPhone.css";
import MyProfilePageSetMailPhoneConfirmCode from "./MyProfilePageSetMailPhoneConfirmCode";
import * as MyProfile from "../../Api/MyProfile";

const MyProfilePageSetMailPhone = () => {

    const [activeBtn, setActiveBtn] = useState(true);
    const btnChangeColorRef = useRef(null);
    const borderRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [valueNewMailPhone, setValueNewMailPhone] = useState({
        email: '',
        phone: ''
    });
    const [typeValue, setTypeValue] = useState({
        email: true,
        phone: false
    });
    const [activeConfirmCodeModal, setActiveConfirmCodeModal] = useState(false);

    const onHandleInput = (e) => {

        if(typeValue.email) {
            setValueNewMailPhone({ phone: '', email: e.currentTarget.value});
        } else {
            setValueNewMailPhone({ email: '', phone: e.currentTarget.value});
        }

        if(e.currentTarget.value) {
            setActiveBtn(false);
            btnChangeColorRef.current.style.background = "#0084FE";
            btnChangeColorRef.current.style.color = "#FFFFFF";
            btnChangeColorRef.current.style.cursor = "pointer";
        } else {
            setActiveBtn(true);
            btnChangeColorRef.current.style.background = "rgba(54, 59, 77, 0.08)";
            btnChangeColorRef.current.style.color = "rgba(54, 59, 77, 0.35)";
            btnChangeColorRef.current.style.cursor = "initial";
        }
    }

    const toggleTypeChange = (typeValue) => {
        if(typeValue === 'email') {
            setTypeValue({ email: true, phone: false});
            setActiveBtn(true);
            setValueNewMailPhone({
                email: '',
                phone: ''
            })
            setErrorMessage('');
        } else {
            setTypeValue({ email: false, phone: true});
            setActiveBtn(true);
            setValueNewMailPhone({
                email: '',
                phone: ''
            })
            setErrorMessage('');
        }
    }

    const onResetBtn = () => {
        setValueNewMailPhone({
                email: '',
                phone: ''
            }
        );
        setTypeValue({
            email: true,
            phone: false
        });
        setErrorMessage('');
        setActiveConfirmCodeModal(false)
        setActiveBtn(true);
        btnChangeColorRef.current.style.background = "rgba(54, 59, 77, 0.08)";
        btnChangeColorRef.current.style.color = "rgba(54, 59, 77, 0.35)";
        btnChangeColorRef.current.style.cursor = "initial";
    }

    const onGetCode = async () => {
        const getCode = await MyProfile.getCodeChangePhoneEmail(typeValue.email ? valueNewMailPhone.email : valueNewMailPhone.phone, typeValue.email ? 'EMAIL' : 'PHONE');

        if (getCode?.status === 409) {
            setErrorMessage(typeValue.email ? "Данный e-mail уже привязан!" : "Данный номер уже привязан!");
            return;
        }

            setErrorMessage('');
            setActiveBtn(true);
            setActiveConfirmCodeModal(true);
    }

    return (
        <div className={"my-profile-page-set-pass__wrapper"}>
            <h3>Почта и номер телефона</h3>
            <div className={"my-profile-page-set-pass__form my-profile-page-set-mail-phone__form"}>
                <div className="my-profile-page-set-mail-phone-change">
                    Изменить <p onClick={() => toggleTypeChange('email')} className={typeValue.email ? "my-profile-page-set-mail-phone__email active" : "my-profile-page-set-mail-phone__email" }>почту</p>
                    /
                    <p onClick={() => toggleTypeChange('phone')} className={typeValue.phone ? "my-profile-page-set-mail-phone__phone active" : "my-profile-page-set-mail-phone__phone"}>номер</p></div>
                <input
                    className={"form-input__pass-fields"}
                    ref={borderRef}
                    type={typeValue.email ? "text" : "number"}
                    value={typeValue.email ? valueNewMailPhone.email : valueNewMailPhone.phone }
                    placeholder={typeValue.email ? 'Введите почту' : '7xxxxxxxxxx'}
                    onChange={(e) => onHandleInput(e)}
                />
                {errorMessage && <p className="my-profile-page-set-mail-phone__error">{errorMessage}</p>}
            </div>
            <button
                disabled={activeBtn}
                ref={btnChangeColorRef}
                onClick={() => {
                    onGetCode();
                }}
                className={"my-profile-page__save-change-button"}
            >
                Сохранить изменения
            </button>
            {activeConfirmCodeModal &&
                <MyProfilePageSetMailPhoneConfirmCode
                    valueNewMail={valueNewMailPhone.email}
                    valueNewPhone={valueNewMailPhone.phone}
                    onResetBtn={onResetBtn}
                    typeValue={typeValue.email}
                    activeConfirmCodeModal={activeConfirmCodeModal}
                />
            }
        </div>
    )
}
export default MyProfilePageSetMailPhone;