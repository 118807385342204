import { useState, useEffect } from "react";

export const useCallCheckAuthUser = (isLoggedIn) => {

    const [isCallCheckAuthUser, setCallCheckAuthUser] = useState("");

    useEffect(() => {
        let isMounted = true;

        function updateLocalStorage() {
            const getCallLogout = localStorage.getItem("callIdUserAuth");

            if (getCallLogout) {
                setCallCheckAuthUser(getCallLogout);
            }
        }

        updateLocalStorage();

        window.addEventListener('storage', updateLocalStorage);

        return () => {
            window.removeEventListener("storage", updateLocalStorage);
            isMounted = false;
        };

    }, [isLoggedIn]);

    return isCallCheckAuthUser;
};