import axios from "axios";
import { config } from "../config";
import useRefreshToken from "../hooks/useRefreshToken";

const apiService = axios.create({
  baseURL: config.java_api_url,
  headers: {
    "Content-type": "application/json",
  },
});

const apiServiceUploadFile = axios.create({
    baseURL: config.java_api_url,
    headers: {
        "Content-type": "multipart/form-data",
    },
});

apiService.interceptors.request.use((axiosConfig) => {
  // Do something before request is sent
  let token = localStorage.getItem("accessToken");
  if (token) {
    axiosConfig.headers["Authorization"] = "Bearer " + token;
  }
  return axiosConfig;
});

apiService.interceptors.response.use(response => response,
    async (error) => {
        const refresh = useRefreshToken();
        const prevRequest = error?.config;
        if(error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refresh();
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken?.accessToken}`;
            return apiService(prevRequest);
        }
        return Promise.reject(error);
    }
);

apiServiceUploadFile.interceptors.request.use((axiosConfig) => {
    // Do something before request is sent
    let token = localStorage.getItem("accessToken");
    if (token) {
        axiosConfig.headers["Authorization"] = "Bearer " + token;
    }
    return axiosConfig;
});

apiServiceUploadFile.interceptors.response.use(response => response,
    async (error) => {
        const refresh = useRefreshToken();
        const prevRequest = error?.config;
        if(error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refresh();
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken?.accessToken}`;
            return apiService(prevRequest);
        }
        return Promise.reject(error);
    }
);

/********************************/

export const getEvents = async () =>
  await apiService.get("/events/me").then((resp) => {
    console.log("resp: ", resp);
    if (resp.data.status === "failure") {
      console.error(resp.data.text);
    }
    return resp.data;
  });
export const getEventById = async (id) =>
  await apiService.get(`/events/me/${id}`).then((resp) => resp.data);

export const getStats = async () =>
  await apiService.get("/common_statistic").then((resp) => resp.data);

export const vote = async (body) => {
  await apiService
    .put(`/events/vote/${body.eventId}`, JSON.stringify(body.eventArray))
    .then((resp) => resp.data);
};

export const joinEventByLink = async (id) =>
  await apiService.get(`/events/join/${id}`).then((resp) => resp.data);

export const registrationUserInEvents = async (id) =>
  await apiService
    .put(`/events/toggle_register/${id}`)
    .then((resp) => resp.data);

export const uploadFile = async (body) => {
    return await apiServiceUploadFile
        .post(`/upload`, body.data)
        .then((resp) => {
            return resp.data;
        });
};