import React, {useState, useRef, useEffect} from "react";
import './AuthorizationTwoFactor.css';
import useCurrentUserContext from "../../hooks/useCurrentUserContext";
import * as Auth from "../../Api/Auth";
import useOnClickOutsideModal from "../../hooks/useOnClickOutsideModal";

const AuthorizationTwoFactor = (props) => {

    const {
        onSuccessEnter,
        isTwoFactor,
        onResetCloseModal
    } = props;

    const { twoFactorAuthLoginDate } = useCurrentUserContext();
    const [isValueCode, setIsValueCode] = useState('');
    const [isTimer, setIsTimer] = useState(false);
    const [isError, setError] = useState(false);
    const [isShowBtn, setShowBtn] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const inputCodeRef = useRef(null);
    const [isButtonRequestCodeDisable, setButtonRequestCodeDisable] = useState(false);

    useOnClickOutsideModal(isTwoFactor, () => onResetCloseModal());

    const onEnterConfirmCode = async (value) => {

        const loginResponse = await Auth.login({
            username: twoFactorAuthLoginDate.username,
            password: twoFactorAuthLoginDate.password,
            type: `${twoFactorAuthLoginDate.type}/code`,
            confirmationCode: value
        });

        if (loginResponse?.status === 404 || loginResponse?.status === 401 || loginResponse?.status === 403) {
            setError(true);
            inputCodeRef.current.style.borderColor = '#FF4970';
            return;
        }

        setError(false);
        setShowBtn(false);
        setIsTimer(false);
        inputCodeRef.current.style.borderColor = '#4ED4A9';
        inputCodeRef.current.style.color = '#4ED4A9';
        inputCodeRef.current.disabled = true;

        setTimeout(()  => {
            onSuccessEnter(loginResponse);
        }, 500)
    }

    const onHandleInput = (e) => {
        let getNumber = e.currentTarget.value;

        if(!getNumber.match(/^\d+$/)) {
            setIsValueCode('');
            return;
        }
        setIsValueCode(e.currentTarget.value);
        if(e.currentTarget.value.length === 6) {
            onEnterConfirmCode(e.currentTarget.value);
        } else {
            setError(false);
            inputCodeRef.current.style.borderColor = 'rgba(54, 59, 77, 0.3)';
            inputCodeRef.current.style.color = 'rgba(54, 59, 77, 0.9)';
        }
    }

    const onGetCode = async () => {
        await Auth.getVerificationCode({
            username: twoFactorAuthLoginDate.username,
            password: twoFactorAuthLoginDate.password,
            type: twoFactorAuthLoginDate.type,
        });
    }

    const onHandleRequestCode = () => {
        onGetCode();
        setButtonRequestCodeDisable(true);
        setIsTimer(true);
        setSeconds(60)
    }

    useEffect(() => {
        let isMounted = true;
        if(isError) {
            isMounted && setShowBtn(true)
        }
        return () => {
            isMounted = false;
        }
    },[isError])

    useEffect(() => {
        let isMounted = true;
        if(seconds > 0 && isTimer) {
            const timer = setTimeout(setSeconds, 1000, seconds - 1);
            return () => clearInterval(timer);
        } else {
            isMounted && setButtonRequestCodeDisable(false);
            isMounted && setIsTimer(false);
        }
        return () => {
            isMounted = false;
        }
    }, [seconds, isTimer]);

    return (
        <div className="auth-two-factor-wrap">
            <div className="auth-two-factor" onClick={(e) => e.stopPropagation()}>
                <h1 className="auth-two-factor__title">
                    Введите код
                </h1>
                <div className="auth-two-factor-form">
                    <label className="auth-two-factor-form__label">Код отправлен на {twoFactorAuthLoginDate?.username}</label>
                    <input
                        ref={inputCodeRef}
                        className="auth-two-factor-form__input"
                        placeholder="Введите код"
                        type="text"
                        value={isValueCode}
                        maxLength={6}
                        onChange={(e) => onHandleInput(e)}
                    />
                    {isError && <p className="auth-two-factor-form__error">Неверный код</p>}
                    {isShowBtn &&
                        <div className="auth-two-factor-form__btn-block">
                            <button
                                disabled={isButtonRequestCodeDisable}
                                className="auth-two-factor-form__button"
                                type={"button"}
                                onClick={() => onHandleRequestCode()}
                            >
                                Отправить код повторно
                            </button>
                            {isTimer && <p className="auth-two-factor-form__timer">Запросить код повторно можно будет через {seconds}</p>}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default AuthorizationTwoFactor;