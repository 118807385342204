import React from "react";
import "./MyProfilePage.css";
import TitleVotesDetailsCallVotingProfile from "../TitleVotesDetailsCallVotingProfile/TitleVotesDetailsCallVotingProfile";
import MyProfilePagePersonalData from "../MyProfilePagePersonalData/MyProfilePagePersonalData";
import MyProfilePageAdditionalSettings from "../MyProfilePageAdditionalSettings/MyProfilePageAdditionalSettings";
import CalendarVotes from "../CalendarVotes/CalendarVotes";
import MyProfilePageSetPassword from "../MyProfilePageSetPassword/MyProfilePageSetPassword";
import { config } from "../../config";
import useCurrentUserContext from "../../hooks/useCurrentUserContext";
import MyProfilePageSetMailPhone from "../MyProfilePageSetMailPhone/MyProfilePageSetMailPhone";
import {useEvents} from "../../fetchers/useEvents";

const MyProfilePage = (props) => {

  const {
    requestHelper,
    utcOffset,
    formatTime,
    formatDate,
    createUserName,
    handleLogout,
    setOffset,
    addCurrentUser,
  } = props;

  const { currentUser } = useCurrentUserContext();

  const userId = currentUser.id;
  const userFirstName = currentUser.firstName;
  const userLastName = currentUser.lastName;
  const userSecondName = currentUser.secondName;
  const userEmail = currentUser.email;
  const userPhone = currentUser.phone;
  // const userType = currentUser.user_type;
  const typeConfig = config.type;
  const {
    data: allEvents,
    isLoading,
    isError,
    isSuccess,
    isRefetching,
  } = useEvents();

  return (
      <div className={"my-profile-page__wrapper"}>
        <TitleVotesDetailsCallVotingProfile
            firstLetter={"Главная"}
            secondLetter={"Мой профиль"}
            titleName={"Мой профиль"}
            hiddenRowBack={true}
        />
        <div className={"my-profile-page__main-content"}>
          <div className={"main-content__grid-item_1"}>
            <MyProfilePagePersonalData
                requestHelper={requestHelper}
                userId={userId}
                userEmail={userEmail}
                userFirstName={userFirstName}
                userLastName={userLastName}
                userSecondName={userSecondName}
                createUserName={createUserName}
                addCurrentUser={addCurrentUser}
                typeConfig={typeConfig}
                userPhone={userPhone}
            />
          </div>
          <div className={"main-content__grid-item_2"}>
            <MyProfilePageAdditionalSettings
                requestHelper={requestHelper}
                userId={userId}
                utc={utcOffset}
                setOffset={setOffset}
                // userType={userType}
                typeConfig={typeConfig}
            />
          </div>
          <div className={"main-content__grid-item_3"}>
            <CalendarVotes
                allEvents={allEvents}
                formatTime={formatTime}
                formatDate={formatDate}
            />
          </div>
          <div className={"main-content__grid-item_5"}>
            <MyProfilePageSetMailPhone/>
          </div>
          <div className={"main-content__grid-item_4"}>
            <MyProfilePageSetPassword
                requestHelper={requestHelper}
                userId={userId}
                typeConfig={typeConfig}
                addCurrentUser={addCurrentUser}
            />
          </div>

        </div>
        <button
            onClick={() => handleLogout()}
            className={"my-profile-page__exit-button-profile"}
        >
          Выйти из профиля
        </button>
      </div>
  );
};

export default MyProfilePage;