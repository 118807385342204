import { useState, useEffect } from "react";
import { getAuthType } from "../Api/Auth";

export const useAuthTypes = () => {
  // Хук возвращает объект authTypes для различного интерфейса входа.
  // Объект с полями, которые выражают различные сочетания пришедших типов

  const [authTypes, setAuthTypes] = useState({});

  useEffect(() => {
    let isMounted = true;
    getAuthType()
        .then((res) => {
          if(res.includes("email/password/code") && !res.includes("phone/password/code")) {
            isMounted && setAuthTypes({
              email: res.includes("email/password/code"),

              emailPassword: res.includes("email/password/code"),
              emailCode: res.includes("email/temporary-code"),

              phone: res.includes("phone/password/code"),

              phonePassword: res.includes("phone/password/code"),
              phoneCode: res.includes("phone/temporary-code"),

              emailAndPhone:
                  res.includes("email/password/code") &&
                  res.includes("phone/password/code"),

              emailPasswordAndCode:
                  res.includes("email/password") &&
                  res.includes("email/temporary-code"),

              emailOnly: res.includes("email/password/code"),

              phoneOnly: res.includes("phone/password/code"),

              emailPasswordOnly: res.includes("email/password/code"),
              emailCodeOnly:
                  res.includes("email/temporary-code") &&
                  !res.includes("email/password"),

              phonePasswordOnly: res.includes("phone/password/code"),

              phoneCodeOnly:
                  res.includes("phone/temporary-code") &&
                  !res.includes("phone/password"),

              phonePasswordAndCode:
                  res.includes("phone/password") &&
                  res.includes("phone/temporary-code"),

              emailPasswordAndCodeTwoFactor:
                  res.includes("email/password/code"),
              phonePasswordAndCodeTwoFactor: false
            });
          } else if(res.includes("phone/password/code") && !res.includes("email/password/code")) {
            isMounted && setAuthTypes({
              email: res.includes("email/password/code"),

              emailPassword: res.includes("email/password/code"),
              emailCode: res.includes("email/temporary-code"),

              phone: res.includes("phone/password/code"),

              phonePassword: res.includes("phone/password/code"),
              phoneCode: res.includes("phone/temporary-code"),

              emailAndPhone:
                  res.includes("email/password/code") &&
                  res.includes("phone/password/code"),

              emailPasswordAndCode:
                  res.includes("email/password") &&
                  res.includes("email/temporary-code"),

              emailOnly: res.includes("email/password/code"),

              phoneOnly: res.includes("phone/password/code"),

              emailPasswordOnly: res.includes("email/password/code"),
              emailCodeOnly:
                  res.includes("email/temporary-code") &&
                  !res.includes("email/password"),

              phonePasswordOnly: res.includes("phone/password/code"),

              phoneCodeOnly:
                  res.includes("phone/temporary-code") &&
                  !res.includes("phone/password"),

              phonePasswordAndCode:
                  res.includes("phone/password") &&
                  res.includes("phone/temporary-code"),

              emailPasswordAndCodeTwoFactor: false,
              phonePasswordAndCodeTwoFactor:
                  res.includes("phone/password/code")
            });
          } else if(res.includes("email/password/code") && res.includes("phone/password/code")) {
            isMounted && setAuthTypes({
              email: false,

              emailPassword: res.includes("email/password/code"),
              emailCode: res.includes("email/temporary-code"),

              phone: false,

              phonePassword: res.includes("phone/password/code"),
              phoneCode: res.includes("phone/temporary-code"),

              emailAndPhone:
                  res.includes("email/password/code") &&
                  res.includes("phone/password/code"),

              emailPasswordAndCode:
                  res.includes("email/password") &&
                  res.includes("email/temporary-code"),

              emailOnly: false,

              phoneOnly: false,

              emailPasswordOnly: false,
              emailCodeOnly:
                  res.includes("email/temporary-code") &&
                  !res.includes("email/password"),

              phonePasswordOnly: false,

              phoneCodeOnly:
                  res.includes("phone/temporary-code") &&
                  !res.includes("phone/password"),

              phonePasswordAndCode:
                  res.includes("phone/password") &&
                  res.includes("phone/temporary-code"),

              emailPasswordAndCodeTwoFactor:
                  res.includes("email/password/code"),
              phonePasswordAndCodeTwoFactor:
                  res.includes("phone/password/code")
            });
          } else {
            isMounted && setAuthTypes({
              email:
                  res.includes("email/password") ||
                  res.includes("email/temporary-code"),

              emailPassword: res.includes("email/password"),
              emailCode: res.includes("email/temporary-code"),

              phone:
                  res.includes("phone/password") ||
                  res.includes("phone/temporary-code"),

              phonePassword: res.includes("phone/password"),
              phoneCode: res.includes("phone/temporary-code"),

              emailAndPhone:
                  (res.includes("email/password") ||
                      res.includes("email/temporary-code")) &&
                  (res.includes("phone/password") ||
                      res.includes("phone/temporary-code")),

              emailPasswordAndCode:
                  res.includes("email/password") &&
                  res.includes("email/temporary-code"),

              emailOnly:
                  (res.includes("email/password") ||
                      res.includes("email/temporary-code")) &&
                  !(
                      res.includes("phone/password") ||
                      res.includes("phone/temporary-code")
                  ),

              phoneOnly:
                  (res.includes("phone/password") ||
                      res.includes("phone/temporary-code")) &&
                  !(
                      res.includes("email/password") ||
                      res.includes("email/temporary-code")
                  ),

              emailPasswordOnly:
                  res.includes("email/password") &&
                  !res.includes("email/temporary-code"),
              emailCodeOnly:
                  res.includes("email/temporary-code") &&
                  !res.includes("email/password"),

              phonePasswordOnly:
                  res.includes("phone/password") &&
                  !res.includes("phone/temporary-code"),
              phoneCodeOnly:
                  res.includes("phone/temporary-code") &&
                  !res.includes("phone/password"),

              phonePasswordAndCode:
                  res.includes("phone/password") &&
                  res.includes("phone/temporary-code"),
            });
          }
        })
        .catch((err) => {
          console.log(err?.message);
          //throw new Error(err?.message || "Неизвестная ошибка");
        });
  }, []);

  return authTypes;
};