import { useState, useEffect } from "react";

function useShowStatusMaterialsVoteBlock(votesData) {

  const [isActiveStatusMaterialsBlock, setActiveStatusMaterialsBlock] = useState(true);

  useEffect(() => {
    let isMounted = true;

    if(votesData?.status === 'ended' && votesData?.type === 'secret' && votesData?.materials?.length === 0) {
        isMounted && setActiveStatusMaterialsBlock(false);
    } else {
        isMounted && setActiveStatusMaterialsBlock(true);
    }

    return () => {
        isMounted = false;
    }
    
  }, [votesData]);

  return { isActiveStatusMaterialsBlock };
}
export default useShowStatusMaterialsVoteBlock;