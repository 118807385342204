import {createContext, useState} from 'react';

const CurrentUserContext = createContext({
    currentUser: {},
    setCurrentUser: (currentUser) => {},
    twoFactorAuthLoginDate: {},
    setTwoFactorAuthLoginDate: (twoFactorAuthLoginDate) => {},
    specialOpinion: {},
    setSpecialOpinion: (specialOpinion) => {}
});

export const CurrentUserContextProvider = ({children})  => {

    const [currentUser, setCurrentUser] = useState({});
    const [twoFactorAuthLoginDate, setTwoFactorAuthLoginDate] = useState({});
    const [specialOpinion, setSpecialOpinion] = useState({});

    return <CurrentUserContext.Provider value={{
        currentUser,
        setCurrentUser,
        twoFactorAuthLoginDate,
        setTwoFactorAuthLoginDate,
        specialOpinion,
        setSpecialOpinion
    }}>
        {children}
    </CurrentUserContext.Provider>
}
export default CurrentUserContext;